<template>
	<errorContainer :error="erreur">
		<div class="animated fadeIn">
			<b-row>
				<b-col>
					<TaskTypeSelector />
				</b-col>
			</b-row>
			<b-row>
				<b-col sm="6" md="4">
					<b-card header="Simple query">
						test : {{ hello }}<br />
						<b-button v-on:click="isProd">isProd</b-button>
						<b-button v-on:click="isDev">isDev</b-button>
						<br />
						{{ shared.isProd }}{{ shared.isDev }}
						<br />
						<b-button v-on:click="downloadContract">download</b-button>
						<br />
						<b-button v-on:click="decryptToken">decryptToken</b-button>
					</b-card>
				</b-col>
				<b-col sm="6" md="4">
					<b-card header="myProjects">
						<ul>
							<li v-for="project in myProjects">
								{{ project.id }} : {{ project.titre }} - <b-button v-on:click="changeProject(project)">Select</b-button>
							</li>
						</ul>
					</b-card>
				</b-col>
				<b-col sm="6" md="4">
					<!-- https://forum.vuejs.org/t/cannot-read-property-of-undefined-yet-the-data-is-displayed/15494/2 -->
					<b-card header="myProject">
						<span v-if="myProject">{{ myProject.id }} : {{ myProject.titre }}</span>
					</b-card>
				</b-col>
			</b-row>
		</div>
	</errorContainer>
</template>

<script>
import gql from 'graphql-tag';
import { BASE_URL } from '../vue-apollo';
import { store } from '@/store';
import TaskTypeSelector from '@/components/TaskType/TaskTypeSelector';
import { getProject, getProjects } from '@/cruds/project.crud.js';

const query_test = gql`
	query {
		hello
	}
`;

const query_myProjects = gql`
	query {
		myProjects {
			id
			titre
		}
	}
`;

const query_myProject = gql`
	query {
		myProject {
			id
			titre
		}
	}
`;

// Variable doit être dans la query : https://github.com/graphql/graphiql/issues/156
const mutation_changeProject = gql`
	mutation ($id: ID!) {
		changeProject(projectID: $id)
	}
`;

export default {
	name: 'test',
	apollo: {
		hello: {
			query: query_test,
			error(err) {
				this.erreur = err;
			}
		},
		myProjects: {
			query: query_myProjects,
			error(err) {
				this.erreur = err;
			}
		},
		myProject: {
			query: query_myProject,
			error(err) {
				this.erreur = err;
			}
		}
	},
	components: {
		TaskTypeSelector
	},
	mounted: async function () {
		this.myProject = await getProject();
		this.myProjects = await getProjects();
		console.log(this.myProject);
	},
	data: function () {
		return {
			// Initialize your apollo data
			hello: 'bonjour',
			myProject: {},
			myProjects: {},
			erreur: {},
			myerr: '',
			shared: store.state
		};
	},
	methods: {
		changeProject: function (project) {
			const id = parseInt(project.id, 10);
			this.$apollo
				.mutate({
					mutation: mutation_changeProject,
					variables: {
						id
					}
				})
				.then((result) => {
					store.onNewToken(this.$apollo.provider.defaultClient, result.data.changeProject); // executer onNewToken avant de storer le nouveau projet, sinon supprimé
					// console.log(result.data.changeProject)
					// store.setProjectAction(project);
					store.setProjectAction(); // project parameters is not in use, removed it
					this.myerr = '';
				})
				.catch((error) => {
					this.erreur = error;
				});
		},
		isProd: function () {
			this.$apollo.provider.defaultClient
				.query({
					query: gql`
						query {
							isProd
						}
					`
				})
				.then((result) => {
					console.log(result);
				})
				.catch((error) => {
					this.erreur = error;
				});
		},
		isDev: function () {
			this.$apollo.provider.defaultClient
				.query({
					query: gql`
						query {
							isDev
						}
					`
				})
				.then((result) => {
					console.log(result);
				})
				.catch((error) => {
					this.erreur = error;
				});
		},
		downloadContract: function () {
			let anchor = document.createElement('a');
			document.body.appendChild(anchor);
			let file = BASE_URL + '/documents/contract';

			let headers = new Headers();
			headers.append('Authorization', 'Bearer ' + this.shared.token);

			// ADD Loading spinner

			fetch(file, { headers })
				.then((response) => response.blob())
				.then((blobby) => {
					let objectUrl = window.URL.createObjectURL(blobby);

					anchor.href = objectUrl;
					anchor.download = 'contract.pdf';
					anchor.click();

					window.URL.revokeObjectURL(objectUrl);
				});
		},
		decryptToken: function () {
			console.log(store.jwtData());
			console.log(store.isProd());
		}
	}
};
</script>

<style>
/* IE fix */
#card-chart-01,
#card-chart-02 {
	width: 100% !important;
}
</style>
