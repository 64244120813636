var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("errorContainer", { attrs: { error: _vm.erreur } }, [
    _c(
      "div",
      { staticClass: "animated fadeIn" },
      [
        _c("b-row", [_c("b-col", [_c("TaskTypeSelector")], 1)], 1),
        _c(
          "b-row",
          [
            _c(
              "b-col",
              { attrs: { sm: "6", md: "4" } },
              [
                _c(
                  "b-card",
                  { attrs: { header: "Simple query" } },
                  [
                    _vm._v("\n\t\t\t\t\ttest : " + _vm._s(_vm.hello)),
                    _c("br"),
                    _c("b-button", { on: { click: _vm.isProd } }, [
                      _vm._v("isProd"),
                    ]),
                    _c("b-button", { on: { click: _vm.isDev } }, [
                      _vm._v("isDev"),
                    ]),
                    _c("br"),
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(_vm.shared.isProd) +
                        _vm._s(_vm.shared.isDev) +
                        "\n\t\t\t\t\t"
                    ),
                    _c("br"),
                    _c("b-button", { on: { click: _vm.downloadContract } }, [
                      _vm._v("download"),
                    ]),
                    _c("br"),
                    _c("b-button", { on: { click: _vm.decryptToken } }, [
                      _vm._v("decryptToken"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "b-col",
              { attrs: { sm: "6", md: "4" } },
              [
                _c("b-card", { attrs: { header: "myProjects" } }, [
                  _c(
                    "ul",
                    _vm._l(_vm.myProjects, function (project) {
                      return _c(
                        "li",
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t" +
                              _vm._s(project.id) +
                              " : " +
                              _vm._s(project.titre) +
                              " - "
                          ),
                          _c(
                            "b-button",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeProject(project)
                                },
                              },
                            },
                            [_vm._v("Select")]
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ]),
              ],
              1
            ),
            _c(
              "b-col",
              { attrs: { sm: "6", md: "4" } },
              [
                _c("b-card", { attrs: { header: "myProject" } }, [
                  _vm.myProject
                    ? _c("span", [
                        _vm._v(
                          _vm._s(_vm.myProject.id) +
                            " : " +
                            _vm._s(_vm.myProject.titre)
                        ),
                      ])
                    : _vm._e(),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }